'use client'

import { useEffect } from 'react'
import { useRouter } from 'next/navigation'
import '@/modules/globalStyleService/globals.scss'
import styles from '@/app/errors.module.scss'

function ErrorPage () {
  const router = useRouter()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.title = '404 - Not Found'
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.messageBox}>
        <h1>404</h1>
        <h2>Resource Not Found</h2>
        <p>The server cannot find the requested resource.</p>
        <div className={styles.goBackSection}>
          <div onClick={() => router.back()} className={styles.link}>Go Back</div>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
